import React from 'react';
import BookCoursecomp from '../../components/bookcourse/BookCoursecomp';
import Banner from '../../components/Program/Banner';

const FreeCoursePage = () => {
  return (
    <div className="">
      <BookCoursecomp paid={false} />
    </div>
  );
};

export default FreeCoursePage;
