export const DUMMY_CERTIFICATES = [
  {
    course_name: "",
    end_date: null,
    is_show_download_action: false,
  },
];

export const DUMMY_NOTES = [
  {
    id: 1,
    file: "",
    week_end_date: null,
  },
  {
    id: 2,
    file: "",
    week_end_date: null,
  },
  {
    id: 3,
    file: "",
    week_end_date: null,
  },
  {
    id: 4,
    file: "",
    week_end_date: null,
  },
  {
    id: 5,
    file: "",
    week_end_date: null,
  },
  {
    id: 6,
    file: "",
    week_end_date: null,
  },
  {
    id: 7,
    file: "",
    week_end_date: null,
  },
  {
    id: 8,
    file: "",
    week_end_date: null,
  },
];
