import React from 'react'

const ReturnRefund = () => {
  return (
    <div className="custom-shadow bg-white !rounded-2xl md:p-12 p-5">
      <div className="md:text-2xl text-xl flex gap-2 items-center  rounded-md font-medium  text-primary my-1">
        {' '}
        <img src={"/assets/svg/terms/termsgreen.svg"} alt="" />
        <span>Return & Refund Policy</span>
      </div>
      <p className=" my-1 max-2xl:text-sm 2xl:text-base text-secondary font-normal">
      Last updated on Jan 14th, 2025
      </p>
      <div class=" mt-5  border-b pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-semibold  text-cblack my-1">
          <span>Definitions and key terms</span>
        </div>
        <p className=" my-2 text-base text-secondary font-normal ">
          To help explain things as clearly as possible in this Return and Refund Policy, every time any of these terms are referenced, they are strictly defined as:
        </p>
        <ul>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">
              Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.
            </p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">
              Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to MAANG Careers, that is responsible for your information under this Return & Refund Policy.
            </p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">
              Customer: refers to the company, organization or person that signs up to use the MAANG Careers Service to manage the relationships with your consumers or service users.
            </p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">
              Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit MAANG Careers and use the services.
            </p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">
              Service: refers to the service provided by MAANG Careers as described in the relative terms (if available) and on this platform.
            </p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">Website: site, which can be accessed via this URL : <a href="#" className='text-primary font-medium'>https://www.maangcareers.com</a></p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">
              You: a person or entity that is registered with MAANG Careers to use the Services.
            </p>
          </li>
        </ul>
      </div>
      <div class=" mt-5  border-b pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-semibold  text-cblack my-1">
          <span>Return & Refund Policy</span>
        </div>
        <p className=" my-2 text-base text-secondary font-normal ">
          Thanks for your course purchase at MAANG Careers. There are terms and conditions that apply to transactions at MAANG Careers. We'll be as brief as our attorneys will allow. The main thing to remember is that by making a purchase at MAANG Careers, you agree to the terms set forth below, along with the policy.
        </p>
        <p className=" my-2 text-base text-secondary font-normal ">If there's something wrong with the course you bought, or if you are not happy with it, you will not be able to issue a refund.</p>
      </div>
      <div class=" mt-5  border-b pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-semibold  text-cblack my-1">
          <span>Refunds</span>
        </div>
        <p className=" my-2 text-base text-secondary font-normal ">
          We pride ourselves on serving our customers with the best IT training courses. Every single course that you choose is thoroughly revised, and evaluated with utmost care. We do this to ensure that you fall in love with our courses.
        </p>
        <p className=" my-2 text-base text-secondary font-normal ">Please note that, any purchases made on this MAANG Careers website, the refund is strictly not applicable for the courses offered.</p>
        <p className=" my-2 text-base text-secondary font-normal ">We follow certain policies to ensure transparency, efficiency and quality customer care:</p>
        <ul>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">Refunds are NOT given for any purchases made online.</p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">We DO NOT encourage exchanges of our courses.</p>
          </li>
        </ul>
      </div>
      <div class=" mt-5  border-b pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-semibold  text-cblack my-1">
          <span>For International Orders:</span>
        </div>
        <ul>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">We DO NOT support Exchanges or Returns.</p>
          </li>
          <li style={{ listStyle: 'outside' }}>
            <p className=" my-2 text-base text-secondary font-normal ">If you face any issues, please contact our Support Team immediately.</p>
          </li>
        </ul>
      </div>
      <div class=" mt-5  border-b pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-semibold  text-cblack my-1">
          <span>Your Consent</span>
        </div>
        <p className=" my-2 text-base text-secondary font-normal ">By using our website, registering an account, or making a purchase, you hereby consent to our Return & Refund Policy and agree to its terms.</p>
      </div>
      <div class=" mt-5 pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-semibold  text-cblack my-1">
          <span>Changes To Our Return & Refund Policy</span>
        </div>
        <p className=" my-2 text-base text-secondary font-normal ">Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Return & Refund Policy. If you do not want to agree to this or any updated Return & Refund Policy, you can delete your account.</p>
      </div>
    </div>
  )
}

export default ReturnRefund