import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateContact } from "../../services/Home.service";
import rightarrow from "../../assets/svg/Rightsidearrow/rightarrowgreen.svg";
import dottedcircle from "../../assets/svg/whitdotcircle.svg";
import greendot from "../../assets/svg/dotcircle.svg";
import phone from "../../assets/svg/phone/bluephone.svg";
import fromImg from "../../assets/request-img.png";
import { Link } from "react-router-dom";

const LastBanner = () => {
  const navigate = useNavigate();

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      const {
        email,
        phone: phone_number,
        location,
        firstName,
        lastName,
        message,
      } = values;

      const payload = {
        email,
        name: `${firstName} ${lastName}`,
        message,
        location,
        phone_number,
      };

      await CreateContact(payload);
      navigate("/detailsubmit");
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      location: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .max(10, "Phone number must be less than 10 characters"),
      location: Yup.string().required("Location is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: submitHandler,
  });

  return (
    <>
      <div className=" relative">
        <div className=" sub-section">
          <div className="  bg-primary  rounded-lg py-8 px-6 flex justify-between items-center max-sm:flex-col">
            <div className=" text-white text-start">
              <h1 className=" text-2xl  font-semibold md:w-[70%] my-2 max-sm:text-xl">
              Unlock Your Learning Potential with MAANG Careers – Book a Call Today to Start Your Journey!
              </h1>
            </div>
            <div className=" relative">
              <img src={dottedcircle} alt="" />
              <img
                src={dottedcircle}
                alt=""
                className=" absolute -top-8 left-12"
              />
            </div>
            <button
              className=" custom-button bg-white text-primary flex gap-2 w-[10/12]"
              onClick={() =>
                window.document
                  .getElementById("modal")
                  .classList.remove("hidden")
              }
            >
              <img src={phone} alt="" />
              <span>Book a Call</span>
            </button>
          </div>
        </div>
        {/* <img src={greendot} alt="" className=" right-0 absolute top-0" /> */}
      </div>
      {/* <div
        id="modal"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 hidden request_call_modal_main z-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg relative modal_main_wrapper z-30">
          <div className="from_wrapper text-left w-[50%]">
            <div className="inner_from_wrapper p-6">
              <div className="heading text-center">
                <h2 h2 className="text-3xl font-bold mb-4">
                  Request a Call Back
                </h2>
                <p className="mb-4 text-sm " style={{ color: "#494949" }}>
                  Simplify your workflow and boost your productivity with{" "}
                  <span className="font-semibold">MAANG Careers</span>. Get
                  started for free.
                </p>
              </div>
              <div className="form_holder mt-5">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border p-2 w-full"
                      placeholder="First Name"
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border p-2 w-full"
                      placeholder="Last Name"
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border p-2 w-full"
                      placeholder="Email"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <input
                      type="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border p-2 w-full"
                      placeholder="WhatsApp Number"
                      onInput={(e) =>
                      (e.currentTarget.value = e.currentTarget.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..?)\../g, "$1")
                        .replace(/^0[^.]/, "0"))
                      }
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="University name"
                      className="border p-2 w-full "
                      placeholder="University"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border p-2 w-full "
                      placeholder="Location"
                    />
                    {formik.touched.location && formik.errors.location ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.location}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <input
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      className="border p-2 w-full "
                      placeholder="Type your message here..."
                    />
                    {formik.touched.message && formik.errors.message ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.message}
                  </div>
                ) : null}
                  </div>
                  <button
                    className="absolute text-black w-8 bg-primary text-white h-8 rounded-full -right-4 -top-2 text-lg p-0"
                    onClick={(event) => {
                      event.preventDefault(); // Prevent any default action
                      document.getElementById("modal").classList.add("hidden");
                    }}
                  >
                    &times;
                  </button>
                  <div className="flex justify-end mt-4">
                    <input
                      type="submit"
                      value="Submit"
                      className="bg-primary text-white px-4 py-2 rounded w-full cursor-pointer"
                    />
                  </div>
                </form>
              </div>
              <div>
                <p className="text-black text-sm text-center mt-6">
                  Not a member?{" "}
                  <Link to="/signup" className="" style={{ color: "#2DB2C4" }}>
                    Register now
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="form_img_wrapper p-7 w-[50%]">
            <div>
              <img src={fromImg} alt="" className="" />
            </div>
            <div className="text text-center mt-14">
              Make your work easier and organized <br /> with{" "}
              <span className="text-black font-semibold">MAANG Careers</span>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LastBanner;