import React, { useEffect, useRef, useState } from "react";
import { ApiBaseURL } from "../ApiConfig";
import axios from "axios";

const WebinarModal = ({ isOpen, onClose, title, children }) => {
    const [page, setPage] = useState(1);
    const [name, setName] = useState("");
    const [error, setError] = useState("");

    const [whatsapp, setWhatsApp] = useState("");
    const [code, setCode] = useState(91);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [selectedDay, setSelectedDay] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [selectedGMeetCode, setSelectedGMeetCode] = useState("");
    const [university, setUnivercity] = useState("");
    const [universityError, setUniversityError] = useState("");

    const [daysToDates, setDaysToDates] = useState([]);
    const isMountedRef = useRef(true); // Ref to track if the component is mounted


    function formatCurrentDateTime() {
        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = currentDate.getFullYear();
        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");
        const seconds = currentDate.getSeconds().toString().padStart(2, "0");

        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    }

    const handleChange3 = (e) => {
        setUnivercity(e.target.value);
        setUniversityError("");
    };


    const getWebinarDate = async () => {
        if (!isMountedRef.current) {
          return;
        } else {
          try {
            const response = await axios.get(`${ApiBaseURL}funnel-date/funneldates/`);
            const data = response.data?.results;
            isMountedRef.current = false;
            setDaysToDates(data);
          } catch (error) {
            // Handle errors, e.g., log the error or throw it again
            console.error("Error fetching webinar date:", error);
            throw error;
          }
        }
      };

    useEffect(() => {
        if(isMountedRef.current){
            getWebinarDate();
        }
    }, [isMountedRef.current]);

    const handleSubmit = () => {
        setPage(2);
    };

    const handleSubmit1 = () => {
        // Validate the name
        if (name.trim() === "") {
            setError("Please enter your name.");
            return;
        }
        // Proceed to the next step
        setPage(3);
    };

    const handleChange = (e) => {
        setName(e.target.value);
        setError("");
    };

    const handleChange2 = (e) => {
        setWhatsApp(e.target.value);
        setError("");
    };

    const handleSubmit3 = () => {
        // Validate the name
        const phoneNumberRegex = /^\d{10}$/; // Assuming a 10-digit phone number
        if (!phoneNumberRegex.test(whatsapp)) {
            // Handle invalid phone number
            setError("Please enter your number to proceed further!");
            return;
        }

        if (code === "") {
            setError("Enter Country code to proceed further");
            return;
        }

        // Proceed to the next step
        setPage(4);
    };

    const handleCode = (e) => {
        setCode(e.target.value);
    };

    const changeEmail = (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        if (!isValid) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError(""); // Clear the error when the email is valid
        }
    };

    const handleSubmit4 = () => {
        // Check if the email is not empty and is valid
        if (email.trim() === "" || emailError !== "") {
            // Handle invalid email (e.g., show an error message)
            setEmailError("Please enter a valid email address");
            return;
        }
        // addToSpreadsheet(email);
        setPage(5);
    };





    const getDayOfWeek = (dateString) => {
        const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const date = new Date(dateString);
        const dayIndex = date.getDay();

        return daysOfWeek[dayIndex];
    };

    const handleDayChange = (e) => {
        const selectedDay = e.target.value;
        setSelectedDay(selectedDay);
        setSelectedDate(getDefaultDate(selectedDay));
        const filteredDates = daysToDates.filter((item) => {
            return item.date === getDefaultDate(selectedDay);
        });
        setSelectedGMeetCode(filteredDates[0].g_meet_code);
    };

    const getDefaultDate = (selectedDay) => {
        const matchingDate = daysToDates.find(
            (item) => getDayOfWeek(item.date) === selectedDay
        );
        return matchingDate ? matchingDate.date : "";
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setSelectedDate(selectedDate);
        setSelectedDay(getDayOfWeek(selectedDate));
        const filteredDates = daysToDates.filter((item) => {
            return item.date === selectedDate;
        });
        setSelectedGMeetCode(filteredDates[0].g_meet_code);
    };

    const handleTimeChange = (e) => {
        const selectedTime = e.target.value;
        setSelectedTime(selectedTime);
    };

    const renderDateOptions = () => {
        return daysToDates.map((item) => (
            <option key={item.date} value={item.date}>
                {formatDateWithSuffix(item.date)}
            </option>
        ));
    };

    const convertToAMPM = (time24) => {
        const [hours, minutes, seconds] = time24.split(":");
        const dummyDate = new Date(2000, 0, 1, hours, minutes, seconds);
        const time12 = dummyDate.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        return time12;
    };

    // Function to set min and max date for current year
    const getCurrentYearDates = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1;
        const startMonth = currentMonth.toString().padStart(2, "0");
        const startDate = `${currentYear}-${startMonth}-01`;
        const endDate = `${currentYear}-12-31`;
        return { startDate, endDate };
    };

    // Set min and max dates for the current year
    const { startDate, endDate } = getCurrentYearDates();

    const handleSubmit5 = () => {
        if (selectedDate === "" || selectedDay === "" || selectedTime === "") {
            // Handle invalid phone number
            setError("Kindly select a slot for the webinar to Continue!");
            setTimeout(() => setError(null), 2000);
            return;
        }
        setPage(6)
    };

    function formatDateWithSuffix(inputDateString) {
        var inputDate = new Date(inputDateString);
        var day = inputDate.getDate();
        var monthIndex = inputDate.getMonth();
        var year = inputDate.getFullYear();
        var monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        function addSuffix(day) {
            if (day >= 11 && day <= 13) {
                return day + "th";
            }
            switch (day % 10) {
                case 1:
                    return day + "st";
                case 2:
                    return day + "nd";
                case 3:
                    return day + "rd";
                default:
                    return day + "th";
            }
        }
        var formattedDate =
            monthNames[monthIndex] + " " + addSuffix(day) + ", " + year;
        return formattedDate;
    }

    // const handleSubmit6 = () => {
    //     if (university.trim() === "") {
    //         setUniversityError("Please enter your location/university");
    //         return;
    //     }
    //     const formattedDateTime = formatCurrentDateTime();
    //     const formData = new FormData();
    //     formData.append("Enrolled Time Stamp", formattedDateTime);
    //     formData.append(
    //         "Webinar Date",
    //         formatDateWithSuffix(sessionStorage.getItem("selectedDate"))
    //     );
    //     formData.append("Webinar Day", selectedDay);
    //     formData.append("Webinar Time", selectedTime);
    //     formData.append("Full Name", name);
    //     formData.append("WhatsApp Number", code + whatsapp);
    //     formData.append("Gmail", email);
    //     formData.append("University", university);
    //     formData.append("Gmeet ", selectedGMeetCode);
    //     // Make an Axios POST request
    //     axios
    //         .post(
    //             "https://sheet.best/api/sheets/add6b09b-d310-4829-85d7-66222be00fb7",
    //             formData
    //         )
    //         .then((response) => {
    //             // Handle successful response
    //             // console.log("Success:", response.data);
    //             setPage(7);
    //         })
    //         .catch((error) => {
    //             // Handle error
    //             console.error("Error:", error);
    //         });
    // };

    const handleSubmit6 = () => {
        if (university.trim() === "") {
            setUniversityError("Please enter your location/university");
            return;
        }
        const formattedDateTime = formatCurrentDateTime();
        const formData = {
            timestamp: formattedDateTime,
            name,
            whatsapp: code + whatsapp,
            email,
            university,
            selectedDate,
            selectedDay,
            selectedTime,
            gMeetCode: selectedGMeetCode,
        };
        axios
            .post(
                "https://script.google.com/macros/s/AKfycbxXgHTvQVVVE0lNTS8Nrk6GEOmYS4BFLjR57STjgDG8LJrxcpOY82frsKiYgSfCwyxlfQ/exec",
                formData.toString(),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((response) => {
                console.log("Success:", response.data);
                setPage(7);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    if (!isOpen) return null; // Don't render anything if modal is closed

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[88888]">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 h-[500px] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-gray-800 text-2xl"
                    >
                        &times;
                    </button>
                </div>
                {page === 1 && (
                    <div className="flex flex-col items-center text-center">
                        <div className="mb-12">
                            <span className="sm:max-w-[232px] max-w-[174px] block mx-auto text-left">
                                <img
                                    src="/images/logo-lg.png"
                                    alt=""
                                    className="inline-block max-w-full"
                                />
                                <br />
                                <label className="sm:max-w-[232px] max-w-[174px] font-base font-medium tracking-[0.5px] text-2xl text-md px-5 block">
                                    Explore the Limits
                                </label>
                            </span>
                        </div>
                        <h1 className="mx-auto mb-12 font-heading md:text-[50px] text-[35px] leading-none text-gray-700 font-semibold max-w-[400px]">
                            Welcome to MAANG Careers!
                        </h1>
                        <a
                            onClick={handleSubmit}
                            className="inline-block py-3 px-6 font-base text-xl font-medium text-black rounded bg-till-600 hover:bg-till-900 duration-150"
                        >
                            Get Started <i className="bx bxs-right-arrow-alt align-middle"></i>
                        </a>
                    </div>
                )}

                {page === 2 && (
                    <div class="funnel-body rounded-[10px] relative">
                        <div class="rounded-[10px] relative z-10 p-2">
                            <div class="mb-10 pt-10 text-center">
                                <img
                                    src="/images/logo-lg.png"
                                    alt=""
                                    class="max-w-[145px] inline-block"
                                />
                            </div>
                            <div class="flex flex-wrap justify-between items-center -mx-5 mb-5 gap-y-5">
                                <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5 pb-6 lg:pb-0">
                                    <img src="/images/funnels-2.svg" alt="" class="w-full" />
                                </div>
                                <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5">
                                    <form action="" className="pb-4 lg:pb-0">
                                        <div className="lg:text-left text-center">
                                            <label
                                                for="price"
                                                class="block mb-4 font-base text-gray-700 text-3xl font-semibold"
                                            >
                                                What Is Your Name?
                                            </label>
                                            <div class="relative mt-2 rounded-md">
                                                <input
                                                    onChange={handleChange}
                                                    value={name}
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    class="block lg:w-[calc(100%-2rem)] w-full text-lg rounded-md border border-slate-300 py-4 px-5 text-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-till-600 focus:outline-none"
                                                    placeholder="Enter your name here"
                                                />
                                                {error && (
                                                    <p className="text-red-500 text-sm mt-1">{error}</p>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="flex justify-between items-center gap-x-5">
                                <ul class="m-0 p-0">
                                    <li class="list-none me-3 inline-block text-gray-400">
                                        <a
                                            href="/terms-and-condition"
                                            class="text-gray-600 hover:text-till-900"
                                        >
                                            Terms & Conditions
                                        </a>
                                    </li>
                                    <li class="list-none me-3 inline-block text-gray-400">|</li>
                                    <li class="list-none me-3 inline-block text-gray-400">
                                        <a
                                            href="/privacy-policy"
                                            class="text-gray-600 hover:text-till-900"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                                <a
                                    type="button"
                                    class="inline-block py-3 px-6 text-xl font-medium text-black rounded bg-till-600 hover:bg-till-700 whitespace-nowrap"
                                    onClick={() => {
                                        handleSubmit1();
                                    }}
                                >
                                    Next <i class="bx bxs-right-arrow-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                {page === 3 && (
                    <div class="funnel-body rounded-[10px] relative">
                        <div class="rounded-[10px] relative z-10 p-2">
                            <div class="mb-10 pt-10 text-center">
                                <img
                                    src="/images/logo-lg.png"
                                    alt=""
                                    class="max-w-[145px] inline-block"
                                />
                            </div>

                            <div class="flex flex-wrap justify-between items-center -mx-5 mb-5 gap-y-5">
                                <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5 pb-10 lg:pb-0">
                                    <img
                                        src="/images/whatsapp.png"
                                        alt=""
                                        class="w-full xl:max-w-[422px]"
                                    />
                                </div>

                                <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5">
                                    <div className="user-name mb-5 lg:text-left text-center">
                                        <h1 className="text-gray-600 lg:text-3xl text-[25px]">
                                            Hey <span className="text-sky-500">{name},</span>
                                        </h1>
                                        <p className="text-gray-600 lg:text-lg text-base font-normal">
                                            Help us personalise your experience by telling us a bit
                                            about your self.
                                        </p>
                                    </div>
                                    <form action="" className="pb-24 lg:pb-0">
                                        <div className="lg:text-left text-center">
                                            <label
                                                for="price"
                                                class="block mb-4 font-base text-gray-700 lg:text-3xl sm:text-[25px] text-xl font-semibold"
                                            >
                                                What Is Your Whatsapp Number?
                                            </label>
                                            <div
                                                style={{
                                                    flexDirection: "row",
                                                    display: "flex",
                                                    width: "100%",
                                                }}
                                            >
                                                <div class="relative mt-2 flex">
                                                    <div class="w-3/12">
                                                        <input
                                                            type="tel"
                                                            name="code"
                                                            id="code"
                                                            class="block w-[calc(100%-1rem)] text-lg rounded-md border border-slate-300 py-4 px-5 text-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-till-600 focus:outline-none"
                                                            placeholder="Country Code"
                                                            onChange={handleCode}
                                                            value={code}
                                                        />
                                                    </div>

                                                    <div class="relative w-9/12">
                                                        <input
                                                            type="tel"
                                                            name="whatsapp"
                                                            id="whatsapp"
                                                            class="block w-[calc(100%-1rem)] text-lg rounded-md border border-slate-300 py-4 px-5 text-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-till-600 focus:outline-none"
                                                            placeholder="Enter your number here"
                                                            onChange={handleChange2}
                                                            value={whatsapp}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {error && (
                                                <p className="text-red-500 mt-2 text-sm">{error}</p>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="flex justify-between items-center gap-x-5">
                                <ul class="m-0 p-0">
                                    <li class="list-none me-3 inline-block text-gray-400">
                                        <a
                                            href="/terms-and-condition"
                                            class="text-gray-600 hover:text-till-900"
                                        >
                                            Terms & Conditions
                                        </a>
                                    </li>
                                    <li class="list-none me-3 inline-block text-gray-400">|</li>
                                    <li class="list-none me-3 inline-block text-gray-400">
                                        <a
                                            href="/privacy-policy"
                                            class="text-gray-600 hover:text-till-900"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                                <a
                                    type="button"
                                    class="inline-block py-3 px-6 text-xl font-medium text-black rounded bg-till-600 hover:bg-till-700 whitespace-nowrap"
                                    onClick={() => {
                                        handleSubmit3();
                                    }}
                                >
                                    Next <i class="bx bxs-right-arrow-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                {page === 4 && (
                    <div class="funnel-body rounded-[10px] relative">
                        <div class="rounded-[10px] relative z-10 p-2">
                            <div class="mb-8 pt-8 text-center">
                                <img
                                    src="/images/logo-lg.png"
                                    alt=""
                                    class="max-w-[145px] inline-block"
                                />
                            </div>

                            <div class="flex flex-wrap justify-between items-center -mx-5 mb-5 gap-y-5">
                                <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5 pb-10 lg:pb-0">
                                    <img src="/images/email.png" alt="" class="w-full" />
                                </div>

                                <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5">
                                    <div className="user-name mb-5 lg:text-left text-center">
                                        <h1 className="text-gray-600 lg:text-3xl text-[25px]">
                                            Hey <span className="text-sky-500">{name},</span>
                                        </h1>
                                        <p className="text-gray-600 lg:text-lg text-base font-normal">
                                            Help us personalise your experience by telling us a bit
                                            about your self.
                                        </p>
                                    </div>
                                    <form action="" className="pb-24 lg:pb-0">
                                        <div className="lg:text-left text-center">
                                            <label
                                                for="price"
                                                class="block mb-4 font-base text-gray-700 lg:text-3xl sm:text-[25px] text-xl font-semibold"
                                            >
                                                What Is Your Email Address?
                                            </label>
                                            <div class="relative mt-2 rounded-md">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    class="block lg:w-[calc(100%-2rem)] w-full text-lg rounded-md border border-slate-300 py-4 px-5 text-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-till-600 focus:outline-none"
                                                    placeholder="Enter your email address"
                                                    value={email}
                                                    onChange={changeEmail}
                                                />
                                            </div>
                                            {emailError && (
                                                <p className="text-red-500 mt-2">{emailError}</p>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="flex justify-between items-center gap-x-5">
                                <ul class="m-0 p-0">
                                    <li class="list-none me-3 inline-block text-gray-400">
                                        <a
                                            href="/terms-and-condition"
                                            class="text-gray-600 hover:text-till-900"
                                        >
                                            Terms & Conditions
                                        </a>
                                    </li>
                                    <li class="list-none me-3 inline-block text-gray-400">|</li>
                                    <li class="list-none me-3 inline-block text-gray-400">
                                        <a
                                            href="/privacy-policy"
                                            class="text-gray-600 hover:text-till-900"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                                <a
                                    type="button"
                                    class="inline-block py-3 px-6 text-xl font-medium text-black rounded bg-till-600 hover:bg-till-700 whitespace-nowrap"
                                    onClick={() => {
                                        handleSubmit4();
                                    }}
                                >
                                    Next <i class="bx bxs-right-arrow-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                {page === 5 && <div class="funnel-body rounded-[10px] relative">
                    <div class="rounded-[10px] relative z-10 p-2">
                        <div class="mb-8 pt-8 text-center">
                            <img
                                src="/images/logo-lg.png"
                                alt=""
                                class="max-w-[145px] inline-block"
                            />
                        </div>

                        <div class="flex flex-wrap justify-between items-center -mx-5 mb-5 gap-y-5">
                            <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5 pb-10 lg:pb-0">
                                <img src="/images/date.png" alt="date" class="w-full" />
                            </div>

                            <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5">
                                <div className="user-name mb-5 lg:text-left text-center">
                                    <h1 className="text-gray-600 lg:text-3xl text-[25px] font-medium">
                                        Hey <span className="text-sky-500">{name},</span>
                                    </h1>
                                    <p className="text-gray-600 lg:text-lg text-base font-normal">
                                        Help us personalise your experience by telling us a bit
                                        about your self.
                                    </p>
                                </div>
                                <form action="" className="pb-24 lg:pb-0">
                                    <div className="lg:text-left text-center">
                                        <label
                                            htmlFor="day"
                                            className="
                    block mb-4 font-base text-gray-700 lg:text-3xl sm:text-[25px] text-xl font-semibold"
                                        >
                                            Select The Webinar Day?
                                        </label>
                                        <div className="p-2 rounded bg-white shadow-2xl shadow-till-200 sm:flex md:gap-x-1 mx-auto sm:max-w-none max-w-[200px]">
                                            <label htmlFor="date"></label>
                                            <select
                                                name="date"
                                                id="date"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                className="py-2 px-3 w-full"
                                            >
                                                <option value="" disabled>
                                                    Select date
                                                </option>
                                                {renderDateOptions()}
                                            </select>

                                            {selectedDate && (
                                                <select
                                                    name="day"
                                                    id="day"
                                                    value={selectedDay}
                                                    onChange={handleDayChange}
                                                    className="py-2 px-3 w-full bg-[#dbeefa] rounded"
                                                >
                                                    <option value="" disabled>
                                                        Select day
                                                    </option>
                                                    {daysToDates.map((item) => (
                                                        <option
                                                            key={item.date}
                                                            value={getDayOfWeek(item.date)}
                                                        >
                                                            {getDayOfWeek(item.date)}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}

                                            <label htmlFor="time"></label>
                                            <select
                                                name="time"
                                                id="time"
                                                value={selectedTime}
                                                onChange={handleTimeChange}
                                                className="py-2 px-3 w-full"
                                            >
                                                <option value="" disabled>
                                                    Select time
                                                </option>
                                                {daysToDates.map((item) => {
                                                    return (
                                                        item.date === selectedDate && (
                                                            <option value={convertToAMPM(item.time)}>
                                                                {convertToAMPM(item.time)}
                                                            </option>
                                                        )
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        {error && (
                                            <p className="text-red-500 mt-2 text-sm">{error}</p>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="flex justify-between items-center gap-x-5">
                            <ul class="m-0 p-0">
                                <li class="list-none me-3 inline-block text-gray-400">
                                    <a
                                        href="/terms-and-condition"
                                        class="text-gray-600 hover:text-till-900"
                                    >
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li class="list-none me-3 inline-block text-gray-400">|</li>
                                <li class="list-none me-3 inline-block text-gray-400">
                                    <a
                                        href="/privacy-policy"
                                        class="text-gray-600 hover:text-till-900"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                            <a
                                type="button"
                                class="inline-block py-3 px-6 text-xl font-medium text-black rounded bg-till-600 hover:bg-till-700 whitespace-nowrap"
                                onClick={() => {
                                    handleSubmit5();
                                }}
                            >
                                Next <i class="bx bxs-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>}

                {page === 6 && <div class="funnel-body rounded-[10px] relative">
                    <div class="rounded-[10px] relative z-10 p-2">
                        <div class="mb-10 pt-10 text-center">
                            <img
                                src="/images/logo-lg.png"
                                alt=""
                                class="max-w-[145px] inline-block"
                            />
                        </div>

                        <div class="flex flex-wrap justify-between items-center -mx-5 mb-5 gap-y-5">
                            <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5 pb-10 lg:pb-0">
                                <img src="/images/location.png" alt="" class="w-full" />
                            </div>

                            <div class="2xl:w-6/12 xl:w-6/12 lg:w-6/12 w-full px-5">
                                <div className="user-name mb-5 lg:text-left text-center">
                                    <h1 className="text-gray-600 lg:text-3xl text-[25px]">
                                        Hey <span className="text-sky-500">{name},</span>
                                    </h1>
                                    <p className="text-gray-600 lg:text-lg text-base font-normal">
                                        Help us personalise your experience by telling us a bit
                                        about your self.
                                    </p>
                                </div>
                                <form action="" className="pb-24 lg:pb-0">
                                    <div className="lg:text-left text-center">
                                        <label
                                            for="price"
                                            class="block mb-4 font-base text-gray-700 lg:text-3xl sm:text-[25px] text-xl font-semibold"
                                        >
                                            What Is Your Location / University?
                                        </label>
                                        <div class="relative mt-2 rounded-md">
                                            <input
                                                type="name"
                                                name="university"
                                                id="university"
                                                class="block lg:w-[calc(100%-2rem)] w-full text-lg rounded-md border border-slate-300 py-4 px-5 text-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-till-600 focus:outline-none"
                                                placeholder="Enter your location/university here"
                                                onChange={handleChange3}
                                                value={university}
                                            />
                                            {universityError && (
                                                <p className="text-red-500 mt-2">{universityError}</p>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="flex justify-between items-center gap-x-5">
                            <ul class="m-0 p-0">
                                <li class="list-none me-3 inline-block text-gray-400">
                                    <a
                                        href="/terms-and-condition"
                                        class="text-gray-600 hover:text-till-900"
                                    >
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li class="list-none me-3 inline-block text-gray-400">|</li>
                                <li class="list-none me-3 inline-block text-gray-400">
                                    <a
                                        href="/privacy-policy"
                                        class="text-gray-600 hover:text-till-900"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                            <a
                                type="button"
                                class="inline-block py-3 px-6 text-xl font-medium text-black rounded bg-till-600 hover:bg-till-700 whitespace-nowrap"
                                onClick={() => {
                                    handleSubmit6();
                                }}
                            >
                                Next <i class="bx bxs-right-arrow-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>}


            </div>
        </div>
    );
};

export default WebinarModal;