import React, { useContext, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OnGoing from "../OnGoing";
import { freeContext } from "../../free/context";

function FreeMockDetails() {
  const { id } = useParams();
  const location = useLocation();
  const { courseId = null } = location.state || {};
  const { screen, setScreen, setIsShowMiniSidebar, isShowMiniSidebar, sectionId,
    setSectionId, selectedCourseIdForMock } =
    useContext(freeContext);


    // Prevent browser reload
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Show confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Disable back navigation
  useEffect(() => {
    const handlePopState = () => {
      // Push the current path to maintain it
      window.history.pushState(null, "", window.location.href);
    };

    // Push the initial state to history
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // Intercept reload shortcuts like Ctrl+R, Cmd+R, and F5
  useEffect(() => {
    const disableReload = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "r") {
        event.preventDefault();
        console.log("Reload is disabled!");
      } else if (event.key === "F5") {
        event.preventDefault();
        console.log("Reload is disabled!");
      }
    };

    window.addEventListener("keydown", disableReload);

    return () => {
      window.removeEventListener("keydown", disableReload);
    };
  }, []);


  useEffect(() => {
    setIsShowMiniSidebar(true);
  }, []);

  useEffect(() => {
    setSectionId(id);
  }, [id])


  useEffect(() => {
    console.log("===== lelo ======", courseId)
  }, [courseId])

  return <OnGoing key={id} sectionId={sectionId} courseId={courseId} />;
}

export default FreeMockDetails;
