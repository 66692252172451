import React from 'react';
import LoginComp from '../../components/login/LoginComp';

const LoginPage = () => {
  return (
    <div className=" ">
      <LoginComp />
    </div>
  );
};

export default LoginPage;
