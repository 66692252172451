import React from "react";
import Homeimg from "../../assets/images/about/about.png";

import dotcircle from "../../assets/svg/dotcircle.svg";
import circle from "../../assets/svg/circle.svg";

const AboutComp = () => {
  return (
    <div className=" relative">
      <section className="sub-section !mt-0 margin_for_header pb-0">
        <div className=" flex justify-center md:items-start items-center max-md:flex-col gap-5 relative z-10 mt-6">
          <div className=" flex-1 text-start flex flex-col gap-4 max-lg:grow-0 max-lg:shrink-0 max-lg:basis-[60%]">
            <h6 className=" text-xl font-medium text-primary">About Us</h6>
            <h1 className=" lg:text-4xl md:text-[32px] text-3xl  font-semibold text-cblack  ">
              Transforming the way of learning <br className=" max-lg:hidden" /> for better IT careers.
            </h1>
            <p className=" text-sm text-secondary md:w-3/4">
              {" "}
              At MAANG Careers, we’re on a mission to reshape the future of tech education. Founded in September 2022
               by a dynamic team of experts from top MAANG companies, we aim to break the mold of traditional learning methods.
                We provide engineering students and tech job seekers with a unique learning experience that goes beyond textbooks, 
                ensuring a perfect blend of theory and hands-on practice. Our personalized interview preparation and in-depth coding 
                resources empower students to unlock their potential and land their dream job. With MAANG Careers, it’s not just about 
                learning to code—it’s about opening doors to lucrative IT careers at world-renowned product-based companies. Join us as 
                we bridge the gap between classroom education and real-world job success. Together, let’s turn your aspirations into reality.
            </p>
          </div>
          <div className="flex-1  w-full">
            <img
              src={Homeimg}
              alt=""
              className=" h-[300px] xl:w-[85%] w-[100%] object-cover rounded-3xl object-top-center"
            />
          </div>
        </div>
      </section>
      <img
        src={circle}
        alt=""
        className=" absolute top-[25%] -right-10 max-lg:hidden"
      />
      <img
        src={dotcircle}
        alt=""
        className=" absolute top-[38%] right-12 max-lg:hidden"
      />
    </div>
  );
};

export default AboutComp;
