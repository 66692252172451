import React from 'react';
import img1 from '../../assets/images/about/choosecomp1.png';
import img2 from '../../assets/images/about/choosecomp2.png';
import img3 from '../../assets/images/about/choosecomp3.png';
import img4 from '../../assets/images/about/choosecomp4.png';
import img5 from '../../assets/images/about/choosecomp5.png';
import img6 from '../../assets/images/about/choosecomp6.png';
import circle from '../../assets/svg/circle.svg';
import dottcircle from '../../assets/svg/dotcircle.svg';

const ChooseComp = () => {
  return (
    <div className=" relative pb-14 choose_about">
      <section className=" sub-section relative z-20">
        <div className=" mx-auto flex flex-col justify-center items-center">
          <h6 className=" text-xl text-primary font-medium">
            Why choose us
          </h6>
          <h4 className="title md:w-2/4 text-center mx-auto mt-2 !mr-auto">
            Our interview preparation strategy is cost and time effective
          </h4>
          <p className=" sub-title md:w-2/4">
          Maximize your preparation with our efficient methods that emphasize practical results, ensuring you're fully equipped for success, not just theory.
          </p>
        </div>
        <div className=" mt-10 grid md:grid-cols-3 sm:grid-cols-2 gap-2 gap-y-10">
          <div className=" flex flex-col gap-1 justify-center items-center my-3">
            {' '}
            <img
              src={img1}
              className=" max-h-20 max-w-20 h-full w-full"
              alt=""
            />
            <h1 className=" 2xl:text-xl md:text-base  font-semibold max-md:text-sm mt-1 text-center ">
            Student Mentorship
            </h1>
            <p className="2xl:text-base md:text-xs max-md:text-xs font-normal text-secondary max-sm:w-4/5 max-sm:w-4/5">
            Unlock your potential with personalized mentorship—perfect your coding skills, ace interviews, and gain hands-on experience with expert guidance.
            </p>
          </div>
          <div className=" flex flex-col gap-1 justify-center items-center my-3">
            {' '}
            <img
              src={img2}
              className=" max-h-20 max-w-20 h-full w-full"
              alt=""
            />
            <h1 className=" 2xl:text-xl md:text-base  font-semibold max-md:text-sm mt-1 text-center ">
            Interactive with MAANG Employees
            </h1>
            <p className=" 2xl:text-base md:text-xs max-md:text-xs font-normal    text-secondary">
            Gain exclusive insights from industry professionals, and leverage their mentorship and network to unlock job referrals and career growth.
            </p>
          </div>
          <div className=" flex flex-col gap-1 justify-center items-center my-3">
            {' '}
            <img
              src={img3}
              className=" max-h-20 max-w-20 h-full w-full"
              alt=""
            />
            <h1 className=" 2xl:text-xl md:text-base  font-semibold max-md:text-sm mt-1 text-center ">
            Interactive Group Sessions
            </h1>
            <p className="2xl:text-base md:text-xs max-md:text-xs font-normal text-secondary max-sm:w-4/5">
            Learn in focused, collaborative groups of 15-30 students, ensuring personalized attention and optimized learning outcomes.
            </p>
          </div>
          <div className=" flex flex-col gap-1 justify-center items-center my-3">
            {' '}
            <img
              src={img4}
              className=" max-h-20 max-w-20 h-full w-full"
              alt=""
            />
            <h1 className=" 2xl:text-xl md:text-base  font-semibold max-md:text-sm mt-1 text-center ">
            Flexible Class Timings
            </h1>
            <p className="2xl:text-base md:text-xs max-md:text-xs font-normal text-secondary max-sm:w-4/5">
            Our evening classes from Monday to Saturday offer you the flexibility to balance your studies with your work schedule.
            </p>
          </div>
          <div className=" flex flex-col gap-1 justify-center items-center my-3">
            {' '}
            <img
              src={img5}
              className=" max-h-20 max-w-20 h-full w-full"
              alt=""
            />
            <h1 className=" 2xl:text-xl md:text-base  font-semibold max-md:text-sm mt-1 text-center ">
            Unique Coding Learning Strategy
            </h1>
            <p className="2xl:text-base md:text-xs max-md:text-xs font-normal text-secondary max-sm:w-4/5">
            Master 30+ coding patterns with 500+ handpicked problems, expertly designed to cover the most essential topics for top-tier company interviews.
            </p>
          </div>
          <div className=" flex flex-col gap-1 justify-center items-center my-3">
            {' '}
            <img
              src={img6}
              className=" max-h-20 max-w-20 h-full w-full"
              alt=""
            />
            <h1 className=" 2xl:text-xl md:text-base  font-semibold max-md:text-sm mt-1 text-center ">
            Up-to-Date Resources
            </h1>
            <p className="2xl:text-base md:text-xs max-md:text-xs font-normal text-secondary max-sm:w-4/5">
            Stay ahead of the game with our student portal, which provides access to real-time quizzes, practice tests, and mock interviews, ensuring comprehensive preparation.
            </p>
          </div>
        </div>
      </section>
      <img src={circle} alt="" className=" absolute -top-14 -right-10 max-lg:hidden" />
      <img src={dottcircle} alt="" className=" absolute top-10 right-10 max-lg:hidden" />
      <span className='glow_elements'></span>
    </div>
  );
};

export default ChooseComp;
