import React from 'react'

const ShippingDeleviry = () => {
  return (
    <div className="  custom-shadow p-5 bg-white z-10"><div className="md:text-2xl text-xl flex gap-2 items-center  rounded-md font-medium  text-primary my-1">
      {' '}
      <img src={"/assets/svg/terms/termsgreen.svg"} alt="" /> <span>Shipping & Delivery Policy
      </span>
    </div>
      <p className=" my-1 max-2xl:text-sm 2xl:text-base text-secondary font-normal">
      Last updated on Jan 14th, 2025
      </p>
      <div class=" mt-5  pb-2 border-cborder">
        <div className="text-xl   items-center  rounded-md font-medium  text-cblack my-1">
          <span>Definitions and key terms
          </span>
        </div>
        <p className=" my-2 text-base text-secondary font-normal ">
          To help explain things as clearly as possible in this Return and Refund Policy, every time any of these terms are referenced, they are strictly defined as:

        </p>
        <p className=" my-2 text-base text-secondary font-normal ">
          Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.
        </p>
        <p className=" my-2 text-base text-secondary font-normal ">
          Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to MAANG Careers, that is responsible for your information under this Return & Refund Policy.
        </p>
        <p className=" my-2 text-base text-secondary font-normal ">
          Customer: refers to the company, organization or person that signs up to use the MAANG Careers Service to manage the relationships with your consumers or service users.
        </p>
      </div>
    </div>
  )
}

export default ShippingDeleviry