import React from 'react'
import ResetComp from '../../components/reset/ResetComp'

const Resetpassword = () => {
  return (
    <div className=''>
        <ResetComp/>
    </div>
  )
}

export default Resetpassword
