// import React, { useEffect, useState } from "react";
// import BlogDetail from "../../components/blog/BlogDetail";
// import Banner from "../../components/Program/Banner";
// import SearchBar from "../../components/searchbar/SearchBar";
// import TabsComp from "../../components/Tabs/TabsComp";
// import BlogsCards from "../../components/blog/BlogsCards";
// import WaveBanner from "../../components/blog/WaveBanner";
// import BlogReadPagination from "../../components/allcarasol/BlogReadPagination";
// import { GetBlogCategory, GetBlogs, GetBlogsByCategory } from "../../services/Blog.service";

// const BlogPage = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [category, setCategory] = useState([]);
//   const [selectedCat, setSelectedCat] = useState({});
//   const [searchQuery, setSearchQuery] = useState(""); // State for search query

//   useEffect(() => {
//     const getBlogCategory = async () =>{
//       try{
//         const blogCategory = await GetBlogCategory();
//         setCategory(blogCategory?.results);
//       }catch(error){
//         console.log(error);
//       }
//     }

//     // getBlogs();
//     getBlogCategory();
//   }, []);

//   useEffect(()=>{
//     if(category && category?.length > 0){
//       setSelectedCat({
//         id: 0,
//         group_name: "Alssl"
//     })
//     }
//   },[category])

//   const getBlogByCategory = async (id) =>{
//     try{
//       const blogs = await GetBlogsByCategory(id);
//       setBlogs(blogs?.results)
//     }catch(error){
//       console.log(error);
//     }
//   }

//   const getBlogs = async () => {
//     try {
//       const blogData = await GetBlogs();
//       const sortedBlogs = blogData?.results.sort(
//         (a, b) => new Date(b.date) - new Date(a.date)
//       );
//       setBlogs(sortedBlogs);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(()=>{
//     if(selectedCat?.id === 0){
//       getBlogs();
//     }
//   },[selectedCat])

  

//   useEffect(()=>{
//     getBlogByCategory(selectedCat?.id)
//   },[selectedCat])


//   const filteredBlogs = blogs.filter(
//     (blog) =>
//       blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       blog.text.toLowerCase().includes(searchQuery.toLowerCase())
//   );


//   return (
//     <div className=" pb-10">
//       <div style={{background: 'linear-gradient(rgb(45 124 196 / 8%) 0%, rgba(45, 151, 196, 0) 100%)'}}>
//         <div className="margin_for_header">
//           <WaveBanner />
//         </div>
//       </div>
//       <div className=" relative -top-5">
//         {" "}
//         <SearchBar setSearchQuery={setSearchQuery}/>
//       </div>
//       <TabsComp category={category} setSelectedCat={setSelectedCat} selectedCat={selectedCat}/>

//       <BlogDetail blogs={filteredBlogs} />
//       <BlogReadPagination blogs={filteredBlogs} />
//     </div>
//   );
// };

// export default BlogPage;




import React, { useEffect, useState } from "react";
import BlogDetail from "../../components/blog/BlogDetail";
import WaveBanner from "../../components/blog/WaveBanner";
import SearchBar from "../../components/searchbar/SearchBar";
import TabsComp from "../../components/Tabs/TabsComp";
import BlogReadPagination from "../../components/allcarasol/BlogReadPagination";
import { GetBlogCategory, GetBlogsByCategory, GetBlogs } from "../../services/Blog.service";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCat, setSelectedCat] = useState({ id: "all", group_name: "All" }); // Default to "All"
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    const fetchBlogCategories = async () => {
      try {
        const blogCategory = await GetBlogCategory();
        setCategory([{ id: "all", group_name: "All" }, ...blogCategory?.results]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogCategories();
    fetchAllBlogs(); // Fetch all blogs on initial load
  }, []);

  const fetchAllBlogs = async () => {
    try {
      const blogData = await GetBlogs();
      const sortedBlogs = blogData?.results.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setBlogs(sortedBlogs);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBlogsByCategory = async (id) => {
    try {
      if (id === "all") {
        fetchAllBlogs();
      } else {
        const blogsByCategory = await GetBlogsByCategory(id);
        setBlogs(blogsByCategory?.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedCat?.id) {
      fetchBlogsByCategory(selectedCat.id);
    }
  }, [selectedCat]);

  // Filter blogs based on the search query
  const filteredBlogs = blogs.filter(
    (blog) =>
      blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      blog.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="pb-10">
      <div
        style={{
          background: "linear-gradient(rgb(45 124 196 / 8%) 0%, rgba(45, 151, 196, 0) 100%)",
        }}
      >
        <div className="margin_for_header">
          <WaveBanner />
        </div>
      </div>
      <div className="relative -top-5">
        <SearchBar setSearchQuery={setSearchQuery} />
      </div>
      <TabsComp category={category} setSelectedCat={setSelectedCat} selectedCat={selectedCat} />
      <BlogDetail blogs={filteredBlogs} />
      <BlogReadPagination blogs={filteredBlogs} />
    </div>
  );
};

export default BlogPage;